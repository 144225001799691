import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Helmet from "react-helmet"

import { graphql } from "gatsby"

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        apiKey
        staging
      }
    }
  }
`

const Login = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const url =
    "https://www." +
    frontmatter.staging +
    "pamyra.de/plugin-manager/manager-plugin.js?apikey=" +
    frontmatter.apiKey

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <Helmet>
        <base href="/" />
        <script src={url} async defer></script>
      </Helmet>
      <div className="w-full">
        <pamyra-manager-plugin></pamyra-manager-plugin>
      </div>
    </Layout>
  )
}

export default Login
